<template>
  <v-card class="pa-6 elevation-0 mt-4">
    <v-card-title class="subtitle-1 nearblack--text font-weight-bold">
      Favorites
    </v-card-title>

    <v-card-subtitle>
      Your reports in one place.
    </v-card-subtitle>

    <v-row class="pb-3">
      <v-col>
        <v-list
          v-for="network in networks"
          :key="network.value"
          class="py-0"
          dense
        >
          <v-list-item-group
            v-for="team in network.teams"
            :key="team.value"
          >
            <v-list-item
              v-for="availableReport in getAvailableReports(team)"
              :key="availableReport"
              :href="makeDeliveryReportHref(availableReport, team)"
              target="_self"
            >
              <v-list-item-title>
                {{ getTeamName(team) }} {{ titlecase(availableReport) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import format from 'src/utils/format'

const { mapGetters, mapState } = createNamespacedHelpers('auth')

export default {
  name: 'NetworkList',
  computed: {
    ...mapGetters(['isInternalUser', 'network']),
    ...mapState({
      user: (state) => state.user,
    }),
    networks() {
      const { network: networksArray } = this
      return networksArray.map((network) => network.publisher)
    },
  },
  methods: {
    titlecase(value) {
      return format.titlecase(value)
    },
    goToDocs() {
      this.$router.push('/docs/releases')
      this.dismissFeaturePrompt()
    },
    async dismissFeaturePrompt() {
      this.dismissed = true

      const { user } = this
      const { email, personEmail, settings = {} } = user
      const alert = { dismissed: true }
      const doc = { settings: { ...settings, alert } }
      const docId = email || personEmail
      const data = { doc, docId }

      return this.$store.dispatch('auth/updateUserSettings', data)
    },
    openLink(url) {
      const target = '_blank'

      window.open(url, target)
    },
    makeDeliveryReportHref(reportName, team) {
      const { reportId } = team

      return `/delivery/${reportName}/${reportId}`
    },
    getAvailableReports(team) {
      const reports = ['pacing']

      if (team.hasDiscrepancy) reports.push('discrepancy')
      if (team.hasHealthAdx) reports.concat(['health/adx'])
      if (team.hasHealthNetwork) reports.concat(['health/network'])
      if (team.hasYieldPartner) reports.concat(['yield/partner'])

      return reports
    },
    getTeamName(team) {
      const { isAllAccess, orgName, text } = team
      return isAllAccess ? orgName : text
    },
  },
}
</script>
